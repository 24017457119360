import React from "react";
import { HeadFC } from "gatsby";

interface PageHeadProps {
  pageTitle: string;
}

export const PageHead: HeadFC<PageHeadProps> = ({ pageTitle }) => {
  return (
    <>
      <title>{pageTitle}</title>

      <meta
        name="facebook-domain-verification"
        content={process.env.GATSBY_VERCEL_FACEBOOK_VERIFICATION}
      />
    </>
  );
};

